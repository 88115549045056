import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Button,
} from "reactstrap"
import companies from "assets/images/companies"

import { Link } from "react-router-dom"
import moment from "moment"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

import { error, success } from "toastr"
import Swal from "sweetalert2"
import { post } from "helpers/api_helper"

const ProductRow = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [shippingBy, setShippingBy] = useState("Surface")

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y, h:mm a")
    return date1
  }

  const handleProjectClick = arg => {
    const project = arg

    setProjectList({
      id: project.id,
      img: project.img,
      name: project.name,
      description: project.description,
      status: project.status,
      color: project.color,
      dueDate: project.dueDate,
      team: project.team,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteProject = project => {
    dispatch(onDeleteProject(project))
  }

  const onClickInvoiced = () => {
    if (props.invoice) {
      window.open(props.invoice)
    } else {
      error("Seller not invoiced")
      props.getBuyerDetails()
    }
  }

  const onClickTrackid = () => {
    if (props.track_id) {
      window.open(props.track_id)
    } else {
      error("Seller not label generated")
      props.getBuyerDetails()
    }
  }

  const onClickApproveOrder = async () => {
    console.log(shippingBy, "shippingByshippingBy")

    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Approved by & Reason",
      inputPlaceholder: "Type here...",
      inputAttributes: {
        "aria-label": "Type here",
      },
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#34c38f",
    })
    if (text) {
      let data = {
        order_id: props.order_id,
        prod_id: props.prod_id,
        is_adminconfirmed: true,
        order_status: "ORDER APPROVED",
        message: text ?? "",
        shipment_mode: shippingBy,
      }
      post(`/api/v1/seller/admin_order_confrm/`, data)
        .then(response => {
          props.getBuyerDetails()
          success("Order Approved")
          // console.log(data, "dattataaaaaa")
        })
        .catch(err => {
          error("Unable to Approve")
          props.getBuyerDetails()
        })
    }
  }

  const onClickCancelOrder = async () => {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Reason",
      inputPlaceholder: "Type reason here...",
      inputAttributes: {
        "aria-label": "Type reason here",
      },
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#34c38f",
    })
    if (text && text.length >= 6) {
      let data = {
        order_id: props.order_id,
        prod_id: props.prod_id,
        is_adminconfirmed: false,
        order_status: "ORDER CANCELLED",
        message: text,
        shipment_mode: null,
      }
      post(`/api/v1/seller/admin_order_confrm/`, data)
        .then(response => {
          props.getBuyerDetails()
          success("Order Cancelled")
        })
        .catch(err => {
          error("Unable to Cancel")
          props.getBuyerDetails()
        })
      // console.log(data, text, "dattataaaaaa")
    }
  }

  return (
    <React.Fragment>
      <tr
        className={`tr-border-${props.color} mb-2`}
        style={{ cursor: "pointer" }}
        onClick={props.onClick}
      >
        <Row>
          <Col lg="10">
            <Row lg="12">
              <Col
                lg="12"
                data-bs-toggle="tooltip"
                title="Click to view product details"
                style={{ cursor: "pointer" }}
              >
                <td
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  <p className="mb-0">{props.prod_name}</p>
                </td>
              </Col>
              <Col>
                <Row>
                  <Col
                    lg="2"
                    data-bs-toggle="tooltip"
                    title="Click to view product details"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={
                        props.image[0] && props.image[0].startsWith("https://")
                          ? props.image[0]
                          : "https://" + props.image[0]
                      }
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    ></img>
                  </Col>
                  <Col>
                    <Row>
                      <Col lg="4">
                        <p className="text-muted mb-2">
                          Brand Name : {props.brand}
                        </p>
                        <p
                          className="text-muted mb-2 cursor-text"
                          onClick={e => e.stopPropagation()}
                        >
                          Vehicle Details : {props?.vehicle_details?.make}{" "}
                          {props?.vehicle_details?.model}{" "}
                          {props?.vehicle_details?.year}{" "}
                          {props?.vehicle_details?.version}{" "}
                          {props?.vehicle_details?.fueltype}
                        </p>
                        <p className="text-muted mb-0">
                          Item : {props.index} /{" "}
                          {props.buyerDetails.total_items}
                        </p>
                        <p className="text-muted mb-2">
                          Date & Time :{" "}
                          {handleValidDate(props.created_at.$date)}
                        </p>
                        <p className="text-muted mb-0">
                          Amount : {props.price}{" "}
                          {props?.cod_charges ? `+ ${props?.cod_charges} ` : ""}
                          | {props.buyerDetails.payment_mode}
                          {/* <span>Amount : </span>
                          {props.buyerDetails?.payment_mode != "COD" ? (
                            <>
                              {props.price} |{props.buyerDetails.payment_mode}
                            </>
                          ) : (
                            <>
                              {props.price} | {props.buyerDetails.payment_mode}
                              <br />
                              <span style={{ visibility: "hidden" }}>
                                Amount :{" "}
                              </span>
                              {props?.cod_charges} | Paid
                            </>
                          )} */}
                        </p>
                        {/* <p className="text-muted mb-0">
                          Amount : {props.price} |{" "}
                          {props.buyerDetails.payment_mode}
                        </p> */}
                      </Col>
                      <Col lg="4">
                        <p className="text-muted mb-0">
                          Product Category : {props.category}
                        </p>
                        {props.type && (
                          <p className="text-muted mb-0">
                            Product type : {props.type}
                          </p>
                        )}

                        <p
                          className="text-muted mb-2 cursor-text"
                          onClick={e => e.stopPropagation()}
                        >
                          Seller part number : {props.seller_part_no}
                        </p>

                        <p className="text-muted mb-0">
                          Total Stock : {props.total_stock}
                        </p>
                        <p className="text-muted mb-2">
                          Quantity : {props.quantity}
                        </p>
                        {props?.logistic_partner && (
                          <p
                            className="text-muted mb-0 cursor-text"
                            onClick={e => e.stopPropagation()}
                          >
                            Logistic Partner : {props?.logistic_partner}{" "}
                            {props?.shipper_name && `| ${props?.shipper_name}`}
                          </p>
                        )}
                      </Col>
                      <Col lg="4">
                        <Row>
                          <Col lg="12">
                            <p
                              className="text-muted mb-0 cursor-text"
                              onClick={e => e.stopPropagation()}
                            >
                              Buyer : {props.buyerDetails.customer_Name}
                            </p>
                          </Col>
                          <Col lg="12">
                            <p
                              className="text-muted mb-0 cursor-text"
                              onClick={e => e.stopPropagation()}
                            >
                              Buyer Contact : {props.buyerDetails.buyer_Phone}
                            </p>
                          </Col>
                          <Col className="mb-2" lg="12">
                            <p className="text-muted mb-0">
                              Buyer City : {props.buyerDetails.buyer_city}
                            </p>
                          </Col>
                          <Col lg="12">
                            <p className="text-muted mb-0">
                              Seller : {props.vendor_name}
                            </p>
                          </Col>
                          <Col lg="12">
                            <p
                              className="text-muted mb-0 cursor-text"
                              onClick={e => e.stopPropagation()}
                            >
                              Seller Contact : {props.vendor_phone}
                            </p>
                          </Col>
                          <Col lg="12">
                            <p
                              className="text-muted mb-2 cursor-text"
                              onClick={e => e.stopPropagation()}
                            >
                              Seller City : {props.vendor_city}
                            </p>
                          </Col>
                          {props.isCancelled ||
                            (!props.is_adminconfirmed && (
                              <Col lg="12">
                                <Row onClick={e => e.stopPropagation()}>
                                  <Col className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`mode_of_shipping${props?.index}`}
                                      id="surface"
                                      value="Surface"
                                      checked={shippingBy == "Surface"}
                                      onClick={() => setShippingBy("Surface")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="surface"
                                    >
                                      Surface
                                    </label>
                                  </Col>
                                  <Col className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`mode_of_shipping${props?.index}`}
                                      id="air"
                                      value="Air"
                                      checked={shippingBy == "Air"}
                                      onClick={() => setShippingBy("Air")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="air"
                                    >
                                      Air
                                    </label>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                        </Row>
                      </Col>

                      {props.admin_message && (
                        <Row>
                          <p className="text-muted mt-4 mb-0">
                            Message : {props.admin_message}
                          </p>
                          <p className="text-muted mb-0">
                            Approved at :{" "}
                            {moment(props?.date?.$date).format(
                              "DD MMM Y, h:mm a"
                            )}
                          </p>
                          {props.shipment_mode && (
                            <p className="text-muted mb-0">
                              Shipment by : {props.shipment_mode}
                            </p>
                          )}
                        </Row>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col lg="2" style={{ alignSelf: "center" }}>
            <button
              className="text-muted mb-0 p-2 text-center bordered-div bg-light bg-gradient w-100"
              data-bs-toggle="tooltip"
              title="Click to view product details"
              style={{
                marginTop: "10%",
                border: "0.5px solid blue",
                cursor: "pointer",
              }}
            >
              Order Status : {props.order_status}
            </button>
            {!props.isCancelled && (
              <>
                <div
                  className="d-flex justify-content-evenly bg-amber-100"
                  style={{ marginTop: "10%" }}
                >
                  <div
                    className="text-muted mb-1 p-2 bordered-div"
                    style={{
                      border: "1px solid",
                      cursor: "pointer",
                    }}
                    onClick={e => {
                      onClickTrackid(), e.stopPropagation()
                    }}
                  >
                    TRACK ID
                  </div>
                  <div
                    className="text-muted mb-1 ms-1 p-2 bordered-div"
                    style={{
                      border: "1px solid",
                      cursor: "pointer",
                    }}
                    onClick={e => {
                      onClickInvoiced(), e.stopPropagation()
                    }}
                  >
                    INVOICE
                  </div>
                </div>
                <div
                  className="d-flex justify-content-evenly"
                  style={{ marginTop: "10%" }}
                >
                  {!props.is_adminconfirmed && (
                    <Button
                      color="success"
                      className="text-capitalize"
                      onClick={e => {
                        onClickApproveOrder(), e.stopPropagation()
                      }}
                    >
                      approve
                    </Button>
                  )}
                  {props.invoice == null && (
                    <Button
                      color="danger"
                      className="text-capitalize ms-1"
                      onClick={e => {
                        onClickCancelOrder(), e.stopPropagation()
                      }}
                    >
                      cancel
                    </Button>
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      </tr>
    </React.Fragment>
  )
}

ProductRow.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(ProductRow)
