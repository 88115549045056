import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Button,
  Label,
  Input,
  Form

} from "reactstrap"
import companies from "assets/images/companies"

import { Link } from 'react-router-dom';
import moment from 'moment';
import Select from "react-select"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"
import SellerRow from './sellerRow'


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom';
import SellerTable from './sellerTable';
import { get } from "../../helpers/api_helper";
const Seller = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('');
  const [pending, setPending] = useState([]);
  const [allData, setAllData] = useState([]);
  const [title, setTitle] = useState("Active");
  const history = useHistory()
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }))



  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])


  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch]);

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const optionGroup = [
    { value: "pending", label: "Pending Approval" },
    { value: "active", label: "Active" },
    { value: "inactive", label: "InActive" },
  ]
  const getData = (url) => {
    get(url).then(res => {
      if (res.data) {
        const pendingData = res.data.filter(items => {
          return items.isApproved == false && items.isRejected == false
        })
        setPending(pendingData);
        setAllData(res.data);
      }
    }).catch(err => {
      setPending([]);
      setAllData([]);
    })
  }
  const onChange = (values) => {
    let url = `/api/v1/seller/seller_tab/detail/`;
    if (values.value == 'active') {
      url = `${url}?isActive=true&isApproved=true`;
    }
    else if (values.value == 'inactive') {
      url = `${url}?isActive=false&isApproved=true`;
    }
    else if (values.value == 'pending') {
      url = `${url}?isApproved=false&isActive=false`;
    }
    setTitle(values.label);
    getData(url);
  }

  useEffect(() => {
    let url = `/api/v1/seller/seller_tab/detail/?isActive=true&isApproved=true`;
    getData(url);
  }, [])

  const handleDeleteProject = project => {
    dispatch(onDeleteProject(project))
  }
  const onClickRow = (items) => {
    history.push(`/seller-details/${items["_id"]["$oid"]}`)
  }
  const onSearch = async(searchValue)=>{
    let url = "/api/v1/search/?q=seller"
    if(searchValue){
      url = `${url}&s=${searchValue}`
    }
    getData(url);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Seller | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Seller")}
            breadcrumbItem={props.t("Seller")}
          />
          <Row className="mb-3">
            <Col lg="7" sm="6">
              <Form className="">
                <div className="search-box me-2">
                  <div className="position-relative">
                    <Input
                      type="text"
                      className="form-control border-0"
                      placeholder="Search Buyer by phone number, ID, Name, Product"
                      onChange={(e)=> onSearch(e.target.value)}
                    />
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
              </Form>
            </Col>
            <Col md="5">
              <div className="search-box me-2 mb-2 col-md-12">
                <Select
                  onChange={(e) => {
                    onChange(e)
                  }}
                  options={optionGroup}
                  defaultValue={{ value: "active", label: "Active" }}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          </Row>
          <Row style={{ float: "right", marginRight:"0px" }}>
            <Button onClick={() => history.push("/add-company")}>
              Add Seller
            </Button>
          </Row>
           <Row>
            <div className="d-flex justify-content-between">
              {title &&
                <h2>
                  {title}
                </h2>}
            </div>
          </Row>
          {/* <Row>
            <div className="d-flex justify-content-between">
              {pending &&
                pending.length > 0 && <h5>
                  Pending Approval
                </h5>}
            </div>
          </Row>
          <br />
          {pending &&
            pending.length > 0 && <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Table className="project-list-table table-nowrap align-middle  table">
                      <tbody>

                        <SellerRow
                          onClick={onClickRow}
                          color={'grey'}
                          pending={pending}
                        />
                        <br />
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>} */}
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <SellerTable color={'grey'} onClick={onClickRow}
                    allData={allData} />
                </div>
              </div>
            </Col>
          </Row>




        </Container>
      </div>


    </React.Fragment>
  )
}

Seller.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Seller)
